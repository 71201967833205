import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Columns, Column, Box } from './bulma';

export default function FeaturesRoll({ alternate, box, cta }) {
	const data = useStaticQuery(FEATURES_QUERY);
	const { edges: items } = data.allMarkdownRemark;

	let i = 0;
	return (
		<div>
			{items &&
				items.map(({ node: item }) => (
					<Box key={item.id}>
						<Columns vertical>
							{Boolean(++i % 2) && (
								<Column>
									{item.frontmatter.image && (
										<figure className="image">
											<PreviewCompatibleImage
												imageInfo={{
													image: item.frontmatter.image,
													alt: `featured image thumbnail for item ${item.title}`
												}}
											/>
										</figure>
									)}
								</Column>
							)}
							<Column size="10">
								<p className="item-meta">
									<Link className="title" to={item.fields.slug}>
										{item.frontmatter.title}
									</Link>
								</p>

								<p>
									{item.frontmatter.subheading}
									<br />
									<br />

									<Link className="button" to={item.fields.slug}>
										Lean More →
									</Link>
								</p>
							</Column>
							{Boolean(++i % 2) && (
								<Column>
									{item.frontmatter.image(
										<figure className="image">
											<PreviewCompatibleImage
												imageInfo={{
													image: item.frontmatter.image,
													alt: `featured image thumbnail for item ${item.title}`
												}}
											/>
										</figure>
									)}
								</Column>
							)}
						</Columns>
					</Box>
				))}
		</div>
	);
}

const FEATURES_QUERY = graphql`
	query FeaturesQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "platform-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						templateKey
						subheading
						date(formatString: "MMMM DD, YYYY")
						image {
							childImageSharp {
								fluid(maxWidth: 2000, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
