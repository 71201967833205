import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/Layout';
import FeatureRoll from '../../components/FeatureRoll';
import ContentSection from '../../components/ContentSection';

import PageHero from '../../components/PageHero';
import CTA from '../../components/CTA';

import { Section } from '../../components/bulma';

import SEO from '../../components/SEO/SEO';

export default function PlatformIndexPage() {
	const data = useStaticQuery(PALTFORM);
	const { edges } = data.allMarkdownRemark;

	const items = edges.map(({ node }) => ({
		title: node.frontmatter.heading,
		text: node.frontmatter.subheading,
		image: node.frontmatter.featuredimage,
		slug: node.fields.slug
	}));

	return (
		<Layout>
			<SEO
				title="Complete Payment Solution for Business"
				description="Learn about Payment.Ninja's cutting-edge platform features."
				// image={image.childImageSharp.sizes.src}
				pathname="/news"
				article
			/>

			<PageHero
				title="CardQL Platform"
				subtitle="Cutting-edge features to improve and secure your payment processing stack at every level."

				// image={featuredImage}
			/>

			<ContentSection items={items} />

			<CTA />
		</Layout>
	);
}

const PALTFORM = graphql`
	query Platform {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "platform-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						heading
						subheading
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
